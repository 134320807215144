.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 20px;
}


/* Add styling for search bar */
.search-bar {
  width: 100%; /* Make the search bar take full width */
  padding: 10px; /* Increase padding for better appearance */
  font-size: 16px; /* Increase font size for better readability */
  margin-bottom: 20px; /* Added margin to create space between search bar and table */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */

/* Add borders to the table */
table {
  border-collapse: collapse;
  width: 100%;
}

/* Add borders to table header */
th {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

/* Add borders to table cells */
td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

